<template>
    <div class="registerS">
        <div class="loginS_log" style="font-size: 40px; font-weight: bold; color: rgb(204, 0, 0); padding: 20px;">
            <!-- <img src="@img/logo.png" alt=""> -->
            <span style="font-size: 40px; font-weight: bold; color: #c00;">华品富商餐饮盈利系统</span>
            <!-- <h2>餐亿本</h2> -->
        </div>
        <!-- <img class="referral_img" src="../../assets/img/referral.png" alt=""> -->
        <div class="loginS_box">
            <div class="loginS_left">
                <p>data</p>
                <div class="loginS_box_l">
                    <h3>{{tiem_day}}</h3>
                    <div>
                        <p>Medium</p>
                        <p>{{tiem_Medium}}</p>
                    </div>
                </div>
            </div>
            <div class="loginS_left">
                <p>time</p>
                <div class="loginS_box_l">
                    <h3>{{timerS}}</h3>
                    <div>
                        <p>Medium</p>
                        <p>{{timerS}}</p>
                    </div>
                </div>
            </div>
            <div class="loginS_rigth">
                <div class="top">
                  <!-- <p @click="$router.back()">返回</p> -->
                  <p @click="back()">返回</p>
                  <p>注册</p>
                </div>
                <div class="register-box-s">
                    <el-form
                      :model="registerUser"
                      class="registerForm register-s"
                      ref="registerForm"
                      label-width="80px"
                    >
                      <div class="registerForm-box">
                        <el-form-item label="" prop="phone" class="registe-k registe-p">
                          <el-input   maxlength="11"  onkeyup="value=value.replace(/[^\d]/g,'')"  v-model="registerUser.phone" placeholder="请输入手机号" ></el-input>
                        </el-form-item>
                        <el-form-item  prop="验证码" class="code registe-k registe-code">
                          <el-input
                            v-model="registerUser.sendcode"
                            onkeyup="value=value.replace(/[^\d]/g,'')"
                            placeholder="请输入验证码"
                          ></el-input>
                          <div class="Fsend">
                            <!-- -->
                            <el-button
                              type="button"
                              :disabled="disabled"
                              @click="sendcode"
                              v-if="disabled == false"
                              >获取验证码
                            </el-button>
                            <!-- @click="sendcode" -->
                            <el-button
                              type="button"
                              :disabled="disabled"
                              v-if="disabled == true"
                              >{{ btntxt }}
                            </el-button>
                          </div>
                        </el-form-item>
                      </div>
                      <el-form-item label="" prop="password" class="registe-k">
                        <i class="phone-m"></i>
                        <el-input
                          :type="mima"
                          v-model="registerUser.password"
                          placeholder="请输入6-20位数字与字母组合密码"
                        ></el-input>
                        <i @click="yanjing" class="gai" ><img :src="suo ? require('../../assets/img/yb.png') : require('../../assets/img/yz.png')"
                            alt=""
                        /></i>
                      </el-form-item>
                      <el-form-item label="" prop="password2" class="registe-k">
                        <i class="phone-m"></i>
                        <el-input
                          :type="mimaa"
                          v-model="registerUser.password2"
                          placeholder="请再次确认"
                        ></el-input>
                        <i
                          @click="yanjing1"
                          class="gai"
                          ><img :src=" suo1 ? require('../../assets/img/yb.png') : require('../../assets/img/yz.png') "
                            alt=""
                        /></i>
                      </el-form-item>

                      <el-form-item>
                        <el-button
                          type="primary"
                          @click="phone_shi()"
                          class="submit_btn"
                          >注 册</el-button
                        >
                      </el-form-item>
                    </el-form>
                    <div class="privacy">
                      <p>点击注册即表示同意<span  @click="dialogVisible = true">《隐私保护政策》</span></p>
                    </div>
                    <div class="register-z cle">
                      <p>
                        <span>已有账号，马上</span><span @click="login"> 登陆 </span
                        ><i></i>
                      </p>
                    </div>
                  </div>

            </div>
        </div>

        <el-dialog
          :visible.sync="dialogVisible"
          width="100%"
          :before-close="handleClose">
          <div>
          <div class="intimity">
                <h2>餐亿本用户隐私保护协议  </h2>
                <h3>（以下简称“本协议”）</h3>
                <div>
                    <p>欢迎使用餐亿本及服务！</p>
                    <p>为使用餐亿本官网（以下简称“本官网”）及服务，请用户务必审慎阅读、充分理 解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独 协议，并选择接受或不接受。限制、免责条款可能以加粗形式提示注意。</p>
                    <p>除非用户已阅读并接受本协议所有条款，否则用户无权下载或使用本官网相关服务。 用户的下载、使用、登录等行为即视为已阅读并同意上述的约束。</p>
                </div>
                <div>
                    <span>• 本协议的范围</span>
                    <p>1.1 本协议适用主体范围： 本协议是您作为用户，与餐亿本（北京）数字化科技 有限公司团队（以下简称“餐亿本”）之间关于下载、使用本官网，以及使用餐添 大数相关服务所订立的协议。</p>
                    <p>1.2 协议关系及冲突条款: 本协议内容包括餐亿本可能不断发布的关于本服务的 相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成 部分，用户同样应当遵守。</p>
                </div>
                <div>
                    <p>2. 关于本服务 </p>
                    <p>2.1 本服务的内容  本服务内容是指餐亿本通过互联网向用户提供的本官网信息使用权，同时为用户 提供本官网内容的阅读、下载和在线咨询服务。</p>
                    <p></p>
                    <p>2.2 本服务的形式 </p>
                    <p>2.2.1 用户使用本官网需要登陆、注册、填写其他表单，就餐亿本官网给予用户一 项不可转让及非排他性的许可。用户仅可为访问或使用本官网的目的而使用这些内 容及服务。</p>
                    <p>2.2.2 本服务中软件提供包括但不限于移动端和PC端等多个应用版本，用户应选择 与所使用环境相相匹配的网站版本。</p>
                    <p>2.3 本服务的范围</p>
                    <p>2.3.1 本条及本协议其他条款未明示授权的其他一切权利仍由餐亿本保留，用户在 行使这些权利时须另外取得餐亿本的书面许可。餐亿本如果未行使前述任何权 利，并不构成对该权利的放弃。</p>
                    <p>2.3.2 如果用户停止使用本官网，或服务被终止或取消，本公司可以从服务器删除您 的数据。服务停止、终止或取消后，本公司没有义务向用户返还任何数据。</p>
                </div>
                <div>
                    <p class="span_name">3. 资料的获取</p>
                    <p>3.1 用户可以直接从餐亿本的网站上获取资料。</p>
                    <p>3.2 如果用户从未经餐亿本授权的第三方获取本官网或与本官网名称相同的资料， 餐亿本无法保证该资料内容正确，并对因此给用户造成的损失不予负责。</p>
                </div>
                <div>
                    <p class="span_name">4. 资料的下载</p>
                    <p>4.1 餐亿本可能为不同的用户提供不同的内容展现形式，用户应当根据实际情况 选择下载或阅读合适的资料。</p>
                    <p>4.2 下载资料后，用户应按照相应类型的资料选择程序打开阅读。</p>
                    <p>4.3 为提供加优质、安全的服务，在本网站打开时餐亿本可能推荐用户安装其他 版本浏览器，用户可以选择安装或不安装。</p>
                </div>
                <div>
                    <p class="span_name">5. 官网更新</p>
                    <p>5.1 为了改善用户体验、完善服务内容，餐亿本将不断努力开发新的服务，并为 用户不时提供本官网的更新（这些新可能会采取替换、修改、功能强化、版本升级 等形式）。</p>
                    <p>5.2 为了保证本官网及服务的安全性和功能的一致性，餐亿本有权不经向用户特 别通知而对本官网进行更新，或者对本官网的部分功能效果进行改变或限制。</p>
                    <p>5.3 本官网新版本发布后，本公司将可能视情况设定一定期限的过渡期，以方便用 户使用新版本。过渡期结束后，旧版本的官网可能无法使用。餐亿本不保证旧版 本官网继续可用及相应的客户服务，请核对并使用新版本。</p>
                </div>
                <div>
                    <p class="span_name">6. 隐私声明</p>
                    <p>6.1 用户信息收集</p>
                    <p>6.1.1 用户在注册帐号或使用本官网的过程中，需要提供一些必要的信息，例如：真 实姓名、地址、籍贯、企业名称、行业、规模、职位、所在部门、电话号码和电子 邮件地址等。上述信息视为您的身份识别信息，本公司用这些信息来管理您的账户， 并为您提供一系列服务的访问。本公司也可能利用这些信息来通知您新的产品和市 场活动。您可以在本公司取得账户信息和密码，并登录查看及修改账户设置和相关 信息。</p>
                    <p>6.1.2 您只能通过您的密码来使用您的帐号。如果您泄漏了密码，您可能丢失了您的 个人识别信息，并且可能出现对您不利的后果。因此，无论任何原因危及您的密码 安全，您应该通过本公司预留的联系方式和本公司取得联系。</p>
                    <p>6.1.3 您成功注册后，本公司将收集您的官网浏览行为，作为您的身份识别信息的一 部分，本公司用这些信息可能为您提供一系列的服务，以方便您更好的使用本网站。</p>
                    <p>6.1.4 本公司还可能收集并存储以下数据：您在本官网中的标识符、经度和纬度、性别、年龄、用户触发的事件、错误和页面浏览量，还可能包含您的IP地址、设备类 型、地区等信息。</p>
                    <p>6.2 用户信息的披露和使用</p>
                    <p>6.2.1 本公司将通过您对本网站的行为来收集相关信息，并以此为您提供更加深入的 支持与服务。</p>
                    <p>6.2.2 本公司不会向任何无关第三方提供，出售，出租，分享和交易用户的个人信息， 但为方便您使用本公司服务，本公司将对您及（或）您的公司的身份和交易数据进 行综合统计，本公司不会披露任何可能用以识别用户的个人身份的资料，但从用户 的用户名或其它可披露资料分析得出的资料不受此限。</p>
                    <p>6.2.3 您同意本公司可披露或使用您的个人信息以用于识别和（或）确认您的身份， 或解决争议，或有助于确保网站安全，限制欺诈、非法或其他刑事犯罪活动。</p>
                    <p>6.2.4 您同意本公司可披露或使用您的个人信息以保护您的生命、财产之安全或为防 止严重侵害他人之合法权益或为公共利益之需要。</p>
                    <p>6.2.5 您同意本公司可披露或使用您的个人信息以改进本公司的服务，并使本公司的 服务能符合您的要求，从而使您在使用本公司服务时得到好的使用体验。</p>
                    <p>6.2.6 您同意本公司利用您的个人信息与您联络，并向您提供您可能感兴趣的信息， 如：介绍产品、服务、促销优惠或者商业投资机会的商业性短信息等，您接受本条 款中的隐私声明即为明示同意收取这些信息。</p>
                    <p>6.2.7 当本公司被法律强制或依照政府或依权利人因识别涉嫌侵权行为人的要求而 提供您的信息时，本公司将善意地披露您的资料。</p>
                    <p>6.2.8 当本公司需要保护本公司、本公司的代理人、客户、用户和其他人的权益和财 产，包括执行本公司的协议、政策和使用条款时，本公司将善意的披露您的资料。</p>
                    <p>6.2.9 您同意如果本公司拟进行企业并购、重组、出售全部或部分股份和/或资产时， 本公司有权在与前述交易的相关方签署保密协议的前提下向其披露您的资料以协助 本公司完成该等交易。</p>
                    <p>6.2.10 您在此同意，在您使用本官网期间，本公司可以在对您的原始数据、进行信 息脱密处理的情况下，将该等信息用于制作及发布行业分析报告、行业交流等非营 利性用途。</p>
                    <p>6.3 用户信息的存储和交换</p>
                    <p>6.3.1 本公司所收集的用户信息将保存在本公司的服务器或本公司的合作产品提供 商的服务器上。本公司将根据本条款中的隐私声明保存收集的用户信息，除非适用 法律要求或允许保存长时间，本公司将在本条款中的隐私声明规定的目的实现后的 合理期限内删除本公司所收集的用户信息。之后，如果为本条款中的隐私声明规定 的目的不再需要，本公司可能在合理时间内完全删除本公司所保存的用户信息。本 公司不去核实试用用户信息是否正确。尽管有上述规定，本公司仍可能保存某些解 决争议、满足技术和法律要求和维护本公司的服务的安全完整运行所需的试用用户 信息</p>
                    <p>6.4 用户信息安全</p>
                    <p>6.4.1 本公司有相应的安全措施来确保本官网收集的用户信息不丢失，不被滥用和变 造。这些安全措施包括但不限于向其它服务器备份数据和对用户密码加密。尽管本 公司有这些安全措施，但请注意在因特网上不存在“完善的安全措施”，因此用户信 息可能非因本公司的原因而丢失，包括但不限于他人非法利用用户资料，用户下载 安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威 胁用户的终端设备信息和数据安全，继而影响本官网的正常使用等。对此，本公司 不承担任何责任。</p>
                    <p>6.5 用户对本软件分析结果的使用</p>
                    <p>6.5.1 用户在此无条件同意，用户在免费使用本公司提供的服务期间，本条款中的隐 私声明项下的信息及对该等信息的分析结果的所有权由本公司与用户共同拥有。本 公司建议，用户应当以符合相关法律规定和道德义务的方式使用该等信息。同时， 本公司提醒注意，因包括但不限于技术原因、网络传输质量等原因，导致本公司对 本公司收集的信息的分析结果可能存在不准确的情况，对于该等不准确所导致的问 题或者损失，本公司不承担任何责任。如果您接受本条款中的隐私声明并把本公司 提供给您的信息、资料集成到您的网站或者应用中，您已经同意并向本公司保证您 所有的终用户同意本公司收集、使用并分析其信息，并且遵守本条款中的隐私声明 的全部规定。</p>
                    <p>6.5.2 您在此进一步保证，您不会因为本公司按照本声明的规定收集、使用、分析、 披露收集的信息及对该等信息的分析结果而对本公司产生任何形式的诉求、投诉等。 如果您因本条款中的隐私声明项下的对其信息的收集、使用、披露或者对该等信息 的分析以及您对分析结果的使用和处分而致使本公司遭受任何形式的诉求以及投诉 等，您将负责全面给予解决；如果导致本公司发生任何形式的损失，您将负责给予 本公司赔偿。</p>
                </div>
                <div>
                    <p class="span_name">7. 用户行为规范</p>
                    <p>7.1 本官网使用规范。除非法律允许或本公司书面许可，用户使用本官网过程中不 得从事下列行为：</p>
                    <p>7.1.1 删除本网站关于著作权的信息；</p>
                    <p>7.1.2 对本网站进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软 件的源代码；</p>
                    <p>7.1.3 对本公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转 载、汇编、发表、出版、建立镜像站点等；</p>
                    <p>7.1.4 对本网站或者本网站运行过程中释放到任何终端内存中的数据、软件运行过程 中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、 修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、 外挂或非经本公司授权的第三方工具/服务接入本软件和相关系统；</p>
                    <p>7.1.5 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为 是否为商业目的；</p>
                    <p>7.1.6 通过非本公司开发、授权的第三方软件、插件、外挂、系统，登录或使用本公 司软件及服务，或制作、发布、传播上述工具；</p>
                    <p>7.1.7 自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；</p>
                    <p>7.2 用户理解并同意，基于用户体验或相关服务平台运营安全、平台规则要求及健 康发展等综合因素，针对以下情形，本公司有权视具体情况中止或终止提供本服务， 包括但不限于：</p>
                    <p>7.2.1 违反法律法规或本协议规定的；</p>
                    <p>7.2.2 影响服务体验的；</p>
                    <p>7.2.3 存在安全隐患的。</p>
                    <p>7.3 违约处理</p>
                    <p>7.3.1 如果本公司发现或收到他人举报或投诉您违反本协议约定的，本公司有权视行 为情节对您处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直 至注销的处罚。</p>
                    <p>7.3.2 您理解并同意，本公司有权依合理判断对违反有关法律法规或本协议规定的行 为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有 关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。</p>
                    <p>7.3.3 您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主 张的任何索赔、要求或损失，用户应当独立承担责任；本公司因此遭受损失的，您 也应当一并赔偿。</p>
                </div>
                <div>
                    <p class="span_name">8. 知识产权声明</p>
                    <p>8.1 餐亿本是本官网的知识产权权利人。本官网的一切著作权、商标权、专利权、 商业秘密等知识产权，均受中华人民共和国法律法规和相应的国际条约保护，本公 司享有上述知识产权，但相关权利人依照法律规定和本条款应享有的权利除外。</p>
                    <p>8.2 未经本公司或相关权利人书面同意，用户不得为任何商业或非商业目的自行或 许可任何第三方实施、利用、转让上述知识产权。</p>
                </div>
                <div>
                    <p class="span_name">9. 第三方软件或技术</p>
                    <p>9.1 本官网可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共 领域代码等，下同），这种使用已经获得合法授权。</p>
                    <p>9.2 本官网如果使用了第三方的软件或技术，本公司将按照相关法规或约定，对相 关的协议或其他文件，可能通过本协议附件、在本官网进行展示，它们可能会以“软 件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各 种形式展现的相关协议、其他文件及网页，均是本协议不可分割的组成部分，与本 协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第 三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求本公司给予协助，您对此应当自行承担法律责任。</p>
                    <p>9.3 如因本官网使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决， 本公司不承担任何责任。本公司不对第三方软件或技术提供客服支持，若用户需要 获取支持，请与第三方联系。</p>
                </div>
                <div>
                    <p class="span_name">10. 其他</p>
                    <p>10.1 用户使用本官网即视为您已阅读并同意接受本协议的约束。本公司有权在必要 时修改本协议条款。用户可以在本软件的新版本中查阅相关协议条款。本协议条款 变更后，如果用户继续使用本官网，即视为用户已接受修改后的协议。如果用户不 接受修改后的协议，应当停止使用本软件。</p>
                    <p>10.2 本协议签订地为中华人民共和国北京市。</p>
                    <p>10.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区 法律（不包括冲突法）。</p>
                    <p>10.4 若用户和餐亿本之间发生任何纠纷或争议，应友好协商解决；协商不成的， 用户同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</p>
                    <p>10.5 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵 义解释的依据。</p>
                    <p>10.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具 有约束力。</p>
                </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
    </div>


</template>
<script>
import { register_judge, getCode, register } from '@api/pulic'
import '../../assets/css/elementuidef.scss'

export default {
  name: 'Register',
  data() {
    // 判断密码是否一致；
    return {
      // 弹窗
      dialogVisible: false,

      tiem_Medium:'',
      timerS: '',//定义一个定时器的变量
      currentTime: new Date(), // 获取当前时间
      tiem_Hours:'',
      visible2: true,
      mima: 'password', // 显示密码
      mimaa: 'password', // 显示密码
      suo: true, // 显示密码切换.
      suo1: true, // 显示密码切换.
      registerUser: {
        phone: '', // 手机
        sendcode: '', // 验证码
        // 密码
        password: '', // 1
        password2: '' // 2
      },
      disabled: false,
      time: 0,
      btntxt: '重新发送'
    }
  },
  methods: {
    handleClose() {},
    yanjing() {
      this.suo = !this.suo
      if (this.suo) {
        this.mima = 'password'
      } else {
        this.mima = 'text'
      }
    },
    yanjing1() {
      this.suo1 = !this.suo1
      if (this.suo1) {
        this.mimaa = 'password'
      } else {
        this.mimaa = 'text'
      }
    },
    // 手机号 验证
    sendcode() {
      const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      if (this.registerUser.phone === '') {
        this.$message({
          message: '手机号不能为空',
          center: true
        })
        return
      }
      if (!reg.test(this.registerUser.phone)) {
        this.$message({
          message: '请输入正确的手机号',
          center: true
        })
      } else {
        console.log(this.registerUser.phone)
        // this.$message({
        //   message: '发送成功',
        //   type: 'success',
        //   center: true
        // })
        this.time = 60
        this.disabled = true
        this.timer()
        this.sms_a()
      }
    },
    // 判断 手机号是否注册过？？
    phone_shi() {
      if (this.registerUser.phone != '' ){
        if (/^1[3456789]\d{9}$/.test(this.registerUser.phone)) {
          console.log(this.registerUser.phone)
          register_judge({
            data: {
              mobile: this.registerUser.phone
            }
          }).then(rea => {
            console.log(rea)
            if (rea.code == 10008) {
              this.submitForm()
              // 可以注册
            } else if (rea.code == 10009) {
              this.$message({
              message: '用户已注册',
              type: 'warning'
            })
            } else {
              this.$message({
                message: '今日以超过最大值',
                type: 'warning'
              })
            }
          })
        } else if (this.registerUser.phone.length != 0) {
          console.log('该手机号已注册')
        }
      } else{
        this.$message({
          message: '您好请您填写完整注册信息',
          type: 'warning'
        })
      }

    },
    // 验证码
    sms_a() {
      getCode({
        data: {
          mobile: this.registerUser.phone
        }
      }).then(res => {
        console.log(res)
        if (res.code == 10000) {
          this.$message({
            message: '今日以超过最大值',
            type: 'warning'
          })
        } else if (res.code == 10001) {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          })
        } else if (res.code == 10002) {
          this.$message({
            message: '短信发送失败',
            type: 'error'
          })
        }
      })
    },
    // 60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--
        this.btntxt = this.time + 's后重新获取'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '获取验证码'
        this.disabled = false
      }
    },
    // 提交之后
    // submitForm(formName) {
    //   this.$refs[formName].validate(valid => {
    //     if (valid) {
    //       console.log('成功')
    //       // register({
    //       //     data: {
    //       //         mobile: this.registerUser.phone,
    //       //         sms: this.registerUser.sendcode,
    //       //         pwd: this.registerUser.password
    //       //     }
    //       // }).then(res => {
    //       //   console,log(res)
    //       //     if (res.data.code == 10006) {
    //       //         this.$message({
    //       //             message: '注册成功',
    //       //             type: 'success'
    //       //         });
    //       //         this.$router.push("/login");
    //       //     } else if (res.data.code == 10007) {
    //       //         this.$message({
    //       //             message: '注册失败',
    //       //             type: 'error'
    //       //         });
    //       //     } else if (res.data.code == 10003) {
    //       //         this.$message({
    //       //             message: '验证码为空',
    //       //             type: 'warning'
    //       //         });
    //       //     } else if (res.data.code == 10005) {
    //       //         this.$message({
    //       //             message: '验证码错误',
    //       //             type: 'error'
    //       //         });
    //       //     }
    //       // });
    //     } else {
    //       console.log(2)
    //       return false
    //     }
    //   })
    // },

    submitForm() {
      const regphone = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
          const pass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      if (this.registerUser.phone === '') {
        this.$message({
          message: '手机号不能为空',
          type: 'error'
        })
        console.log(this.registerUser.password.length + 'asd')
      } else if (!regphone.test(this.registerUser.phone)) {
        this.$message({
          message: '请你输入正确的手机号',
          type: 'error'
        })
      } else if (this.registerUser.password === '') {
        this.$message({
          message: '密码不能为空',
          type: 'error'
        })
      } else if (!pass.test(this.registerUser.password)) {
        this.$message({
          message: '请输入6-20位数字与字母组合密码',
          type: 'error'
        })
      } else if (this.registerUser.password.length < 6 && this.registerUser.password.length > 12) {
        this.$message({
          message: '长度在 6 到 12 个字符',
          type: 'error'
        })
      } else if (this.registerUser.password2 === '') {
        this.$message({
          message: '再次确认密码不能为空',
          type: 'error'
        })
      }  else if (this.registerUser.password !== this.registerUser.password2) {
        this.$message({
          message: '密码需要一致',
          type: 'error'
        })
      } else {
        register({
          data: {
            mobile: this.registerUser.phone,
            sms: this.registerUser.sendcode,
            pwd: this.registerUser.password
          }
        }).then(res => {
          console.log(res)
          if (res.code == 10006) {
            this.$message({
              message: '注册成功',
              type: 'success'
            })
            this.$router.push('/')
          } else if (res.code == 10007) {
            this.$message({
              message: '注册失败',
              type: 'error'
            })
          } else if (res.code == 10003) {
            this.$message({
              message: '验证码为空',
              type: 'warning'
            })
          } else if (res.code == 10005) {
            this.$message({
              message: '验证码错误',
              type: 'error'
            })
          } else if (res.code == 10009) {
            this.$message({
              message: '用户已注册',
              type: 'error'
            })
          }
        })
      }
    },
    login() {
      this.$router.push('/')
    },
    back(){
      this.$router.push('/')
    }
  },
   created: function() {
        var aData = new Date();
        // 获取当前月日
        this.tiem_Medium =  aData.getFullYear() + "年" + (aData.getMonth() + 1) + "月"       // 获取当前日期
        this.tiem_day =  aData.getDate();

        // this.timerS =  aData.getHours() + ':' + aData.getMinutes()

         if(aData.getMinutes() <= 9){
           this.timerS = aData.getHours() + ':' + 0 + aData.getMinutes()
         } else{
           this.timerS = aData.getHours() + ':' + aData.getMinutes()
         }
    },
}
</script>

<style scoped lang="scss">

.intimity{
    padding: 60px 40px 40px;
    // height: 100%;
    position: relative;
    // overflow-y: scroll;
    // overflow-x: auto;
    .intimity_f{
        position: absolute;
        font-size: 20px;
        color: #666;

        width: 120px;
        height: 40px;
        line-height: 40px;
        border: 1px #666 solid;
        text-align: center;
        top: 30px;
        left: 30px;
    }
    h2{
        font-size: 30px;
        color: #333;
        margin-bottom: 26px;
        text-align: center;
    }
    span,p{
        display: block;
    }
    .span_name{
        color: #333;
    }
    span{
        color: #333;
        font-size: 20px;
        margin-bottom: 10px;
    }
    h3{
        font-size: 20px;
        color: #333;
        margin-bottom: 26px;
        text-align: center;
    }
    div{
        margin-bottom: 20px;
    }
    p{
        font-size: 20px;
        line-height: 30px;
        color: #666;
        margin-bottom: 6px;
    }
}


.registerS .registerForm-box .registe-code{
  width: 53%;
}
.registerS{
  .el-dialog{
    margin: 0;
  }
    position: relative;
    background-image: url(../../assets/img/toRegister.png);
    background-size: 100% 100%;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    .referral_img{
      position: absolute;
      left: 5%;
      top: 46%;
      transform: translate(0,-50%);
    }

    .loginS_log{
        h2{
            font-size: 34px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
        img{
            margin: 40px 0 0 40px;
        }
    }
    .loginS_box{
        width: 80%;
        height: 200px;
        position: fixed;
        left: 50%;
        transform: translate(-50%);
        bottom: 40px;
        background: #2D4662;
        border-radius: 24px;
        display: flex;
        padding: 30px 30px;
        box-sizing: border-box;
        .loginS_left{
            margin-right: 200px;
            & > p{
                font-size: 22px;
                font-weight: 500;
                color: #75A4D9;
                margin-bottom: 26px;
            }
            .loginS_box_l{
                display: flex;
                 h3{
                    font-size: 70px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                     margin-right: 18px;
                 }
                div{
                    width: 150px;
                    margin-top: 10px;
                    p{
                        font-size: 22px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #75A4D9;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .loginS_rigth{
          background: rgba(45, 70, 98, .5);
          border-radius: 20px;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 390px;
          margin: 0 auto;
          padding: 10px 20px 20px 20px;
         >.top{
            font-size: 16px;
            display: inline-flex;
            justify-content:space-around;
            line-height: 40px;
            width: 70%;
            >p{
              color: #fff;
              &:last-of-type{
                color: #4AF0FF;
                font-size: 20px;
              }
            }
          }
          .register-box-s{
            width: 90%;
            margin: 0 auto;
          }
          .registe-k{
            position: relative;
            .gai{
              position: absolute;
              right: 16px;
              top: 6px;
            }
          }
          .privacy {
            p {
              color: #bababa;
              font-size: 16px;
              margin-top: 20px;

              span {
                color: #fc4353;
                &:hover{
                  cursor: pointer;
                }
              }
            }
          }
          .register-z {
            margin-top: 14px;
            & :hover {
              cursor: pointer;
            }
            p {
              display: flex;
              span {
                color: #c5c5c5;
                font-size: 16px;
              }
              & span:last-of-type {
                color: #fc4353;
              }
              i {
                background: url('../../assets/img/deng.png') no-repeat;
                width: 28px;
                height: 26px;
                background-size: 20px;
                display: block;
                margin-left: 10px;
              }
            }
          }
        }

    }
}
</style>
